<template>
  <div class="form-box">
    <van-form @submit="onSubmit" validate-first>
      <van-field
        readonly
        clickable
        name="estateName"
        :value="form.opinionTypeName"
        label="意见类型"
        right-icon="arrow"
        placeholder="请选择意见类型"
        label-width="4.2em"
        required
        :rules="[{ required: true }]"
        @click="showEstatePicker = true"
      />
      <van-popup v-model="showEstatePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="typeColumns"
          @confirm="confirmType"
          value-key="dictValue"
          @cancel="showEstatePicker = false"
        />
      </van-popup>
      
      <van-field
        v-model="form.description"
        label="意见内容"
        name="description"
        label-width="4.2em"
        type="textarea"
        required
        :rules="[{ required: true }]"
        placeholder="请输入意见内容"
      />
      
      <van-field name="imgList" label-width="4.2em" label="图片">
        <template #input>
          <van-uploader
            v-model="imgList"
            @oversize="oversize"
            :beforeDelete="beforeDelete"
            :max-size="5 * 1024 * 1024"
            :max-count="5"
            :afterRead="afterRead"
            :preview-size="80"
          />
        </template>
      </van-field>
      <van-button class="submit-btn" block type="info" native-type="submit" :loading="subLoading">提交</van-button>
     
    </van-form>
  </div>
</template>
<script>
import { subWarning } from '@/api/eventApi'
import axios from '@/utils/axios';
import { getDictionary } from '@/api/registerApi.js'
export default {
  data() {
    return {
      typeColumns:[],
      showEstatePicker: false,
      form: {
        place:'',
        picUrl: [],
      },
      imgList: [],
      subLoading: false,
      type: '',
    }
  },
  created() {
    this.getTypeList()
  },
  methods: {
    getTypeList(){
      getDictionary('opinion_type').then(res => {
        this.typeColumns = res.data
      })
    },
    confirmType(e){
      this.form.opinionType = e.id
      this.form.opinionTypeName = e.dictValue
      this.showEstatePicker = false
    },
    onSubmit() {
      this.subLoading = true
      // 0 事件上报、1 民事调解、 3警情上报
      this.form.eventType = 5
      let arr = this.form.picUrl
      this.form.picUrl = ''
      this.form.picUrl = arr.join(',')
      this.form.openid = this.$store.getters.openId
      this.form.status = 0

      subWarning(this.form).then(res => {
        this.subLoading = false
        if(res.code == 200) {
          
          this.form = {
            picUrl: []
          }
          this.imgList = []
          this.$toast({
            type: 'success',
            message: '上报成功',
            duration: 1000,
            onClose: () => {
              // this.$router.go(-1)
              // this.$router.replace('/myOpinionList')
              window.location.href = '/myOpinionList'
            }
          })
        } else {
          this.$toast({
            type: 'error',
            message: res.msg,
            duration: 1000,
          })
        }

      }).catch(() => {
        this.subLoading = false
      })
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    // 删除图片
    beforeDelete(file, detail) {
      this.imgList.splice(detail.index, 1)
      this.form.picUrl.splice(detail.index, 1)
    },
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    afterRead(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      let url = file.file

      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          file.url = res.data.url
          this.form.picUrl.push(res.data.url)
          file.status = 'done'
          file.message = ''
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    // 上传图片接口
    upload(file) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData()
        formdata.append('file', file)

        axios({
          baseURL: '/minio',
          url: '/minio/villageUpload?keepFileName=1&bucketName=village',
          method: 'post',
          data: formdata
        }).then(res => {
          if(res.code == 200) {
            resolve(res)
          } else {
            reject(res)
          }
        })
        
      })
    },
  },
}
</script>
<style scoped lang="scss">

</style>
